import { AxiosResponse, CancelTokenSource } from "axios";
import $api from "../http";

// Types
import { IReportColorResponse, IReportDetailsResponse, IReportResponse, ITag } from "../models/response/report-response";

export default class ReportService {
    getReportList(cancelToken: CancelTokenSource, instance_id: string, page: string, count: string, matches: string): Promise<AxiosResponse<IReportResponse>> {
        return $api.get<IReportResponse>(`/report/match?instance_id=${instance_id}&color=${matches}&page=${page}&size=${count}`, { cancelToken: cancelToken.token, baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReportListReconStory(cancelToken: CancelTokenSource, recon_story_id: string, page: string, count: string, matches: string, search: string): Promise<AxiosResponse<IReportResponse>> {
        return $api.get<IReportResponse>(`/report/match/all?recon_story_id=${recon_story_id}&color=${matches}&page=${page}&size=${count}${Boolean(search) ? `&search=${search}` : ''}`, { cancelToken: cancelToken.token, baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReportColor(instance_id: string): Promise<AxiosResponse<IReportColorResponse>> {
        return $api.get<IReportColorResponse>(`/report/color?instance_id=${instance_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReportColorReconStory(recon_story_id: string): Promise<AxiosResponse<IReportColorResponse>> {
        return $api.get<IReportColorResponse>(`/report/color/all?recon_story_id=${recon_story_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReport(instance_id: string): Promise<AxiosResponse<IReportDetailsResponse>> {
        return $api.get<IReportDetailsResponse>(`/report/instance_result?instance_id=${instance_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReportReconStory(recon_story_id: string): Promise<AxiosResponse<IReportDetailsResponse>> {
        return $api.get<IReportDetailsResponse>(`/report/instance_result/all?recon_story_id=${recon_story_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    setTag(recon_story_id: string, data: ITag): Promise<AxiosResponse<any>> {
        let tags = { "tags": [{ ...data }] }
        return $api.post(`/tag/${recon_story_id}`, tags, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    deleteTag(id: string | number): Promise<AxiosResponse<any>> {
        return $api.delete(`/tag?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    download_csv_file(id: string | number, colors: string): Promise<AxiosResponse<any>> {
        return $api.get(`/csv/match/all?color=${colors}&recon_story_id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
}