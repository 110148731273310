import React from "react";
import {
    useLocation,
    Navigate,
} from "react-router-dom";

type ProtectedRouteProps = {
    children: JSX.Element,
    redirectСondition: boolean | undefined,
    redirectPath: string,
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectСondition, redirectPath }) => {
    let location = useLocation();

    if (!redirectСondition) {
        return <Navigate to={redirectPath} state={{ from: location }} />;
    }

    return children;
}

export default ProtectedRoute