type checkboxType = {
    name: string,
    value: string
}


export const dashboardUsersFilterList: checkboxType[] = [
    {
        name: 'By me',
        value: "1",
    },
    {
        name: 'By others',
        value: '0'
    }
]

export const dashboardProjectsFilterList: checkboxType[] = [
    {
        name: 'Payments',
        value: 'payments'
    },
    {
        name: 'Finance',
        value: 'finance'
    },
    {
        name: 'Trading',
        value: 'trading'
    }
]


export const dashboardTimeFilterList: checkboxType[] = [
    {
        name: 'Newest',
        value: 'asc'
    },
    {
        name: 'Oldest',
        value: 'desc'
    }
]