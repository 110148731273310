import { AxiosResponse, CancelTokenSource } from "axios";
import $api from "../http";

// Types
import {
  IReconStoryByIdResponse,
  IReconStoryResponse,
} from "../models/response/recon-story-response";
import { ITagItemResponse, ICheckboxItem } from "../models/response/report-response";
import { IDashboardFilter } from "../store/reducers/dashboard-filter";

export default class ReconStoryService {
  getReconStoryList(
    cancelToken: CancelTokenSource,
    currentPage: number,
    size: number,
    data: IDashboardFilter
  ): Promise<AxiosResponse<IReconStoryResponse>> {
    const operator = data?.users?.length === 1 ? data?.users : "";
    const status = data?.status?.length ? data.status.join(",") : "";
    const time = data?.time?.length ? data.time : "";

    return $api
      .get<IReconStoryResponse>(
        `/recon_story?page=${currentPage}&size=${size}&by_operator=${operator}&status=${status}&sort=${time}`,
        {
          cancelToken: cancelToken.token,
          baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
        }
      )
      .then((res) => {
        return Promise.resolve(res);
      });
  }
  getReconStoryById(
    id: number | string
  ): Promise<AxiosResponse<IReconStoryByIdResponse>> {
    return $api
      .get<IReconStoryByIdResponse>(`/recon_story/${id}`, {
        baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
      })
      .then((res) => {
        return Promise.resolve(res);
      });
  }
  getTags(): Promise<AxiosResponse<ITagItemResponse[]>> {
    return $api
      .get<ITagItemResponse[]>(`/reference?group_id=RESULT_MANUAL_TAGS`, {
        baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
      })
      .then((res) => {
        return Promise.resolve(res);
      });
  }
  setCheckbox(data: ICheckboxItem[]): Promise<AxiosResponse<any>> {
    return $api.post<any>(`/supervisor`, { items: data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
      return Promise.resolve(res)
    })
  }

  deleteCheckbox(data: any): Promise<AxiosResponse<any>> {
    return $api.delete<any>(`${process.env.REACT_APP_API_URL_RECON_STORY}/supervisor`, { data: { items: data } }).then(res => {
      return Promise.resolve(res)
    })
  }
}
