import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import AuthService from '../../services/auth-service'

// Utils
import getTokens from '../../utils/get-tokens'
import removeTokens from '../../utils/remove-tokens'
import setTokens from '../../utils/set-tokens'

// Actions
import { getUser } from './user'

const Auth = new AuthService()

interface IAuth {
    error: any,
    loading: boolean,
    isAuth: boolean | undefined
}

const initialState: IAuth = {
    error: null,
    loading: false,
    isAuth: undefined
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authFetching(state) {
            state.loading = true
        },
        authFetchingSuccess(state) {
            state.loading = false
            state.error = null
            state.isAuth = true
        },
        authFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = action.payload
        },
        authLogout(state) {
            state.loading = false
            state.error = null
            state.isAuth = false
        },
    }
})

const { authFetching, authFetchingSuccess, authFetchingError, authLogout } = authSlice.actions

export const userAuth = (login: string, password: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authFetching())
        const authResponse = await Auth.login(login, password)
        setTokens(authResponse.data.access_token, authResponse.data.refresh_token)
        await dispatch(getUser())
        dispatch(authFetchingSuccess())
    } catch (error: any) {
        dispatch(authFetchingError(error))
    }
}

export const userLogout = () => async (dispatch: AppDispatch) => {
    removeTokens()
    dispatch(authLogout())
}

export const userAuthCheck = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(authFetching())
        const { access } = getTokens()
        if (access) {
            await dispatch(getUser())
            dispatch(authFetchingSuccess())
        } else {
            dispatch(userLogout())
        }
    } catch (error: any) {
        dispatch(userLogout())
    }
}


export default authSlice.reducer

// const { authFetching } = authSlice.actions
// export default initialState