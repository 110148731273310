import { AxiosResponse } from "axios";
import decode from 'jwt-decode';
import $api from "../http";

// Types
import { IUser } from "../store/reducers/user";

// Utils
import getTokens from "../utils/get-tokens";

export default class UserService {
    async getUserById(): Promise<AxiosResponse<IUser>> {
        const { access } = getTokens()
        let id: string = ''
        if (access) {
            const { user_id }: { user_id: string } = decode(access)
            id = user_id
        }
        return $api.get<IUser>(`/users/${id}`)
    }
    getUsers(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/users`).then(res => {
            return Promise.resolve(res)
        })
    }
    getUsersByRole(role: string): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/users/roles/${role}`).then(res => {
            return Promise.resolve(res)
        })
    }
    getUser(id: any): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/users/${id}`).then(res => {
            return Promise.resolve(res)
        })
    }
    saveUser(id: any, data: any): Promise<AxiosResponse<any>> {
        return $api.patch<any>(`/users/${id}`, { ...data }).then(res => {
            return Promise.resolve(res)
        })
    }
    addUsers(user: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/users`, { ...user }).then(res => {
            return Promise.resolve(res)
        })
    }
    deleteUser(id: any): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/users/${id}`).then(res => {
            return Promise.resolve(res)
        })
    }
}