import React from 'react'
import { Menu } from '@szhsin/react-menu'
import { Link, useNavigate } from "react-router-dom"

// Images
import logo from '../../assets/img/dark-header-logo.svg'

// Constants
import { configCreate, configTemplate, dataprovidersPath, headerNavArr, rolesPath, usersPath, workflowPath } from '../../constants/navigation'

// Components
import CustomLink from '../custom-link'
import CustomMenuItem from '../custom-menu-item'

// Hooks
import { useAppDispatch } from '../../hooks/redux'

// Actions
import { userLogout } from '../../store/reducers/auth'

const DarkHeader: React.FC = () => {

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const handleRedirect = (path: string) => {
        navigate(path)
    }

    return (
        <header className="dark-header">
            <Link to="/">
                <img src={logo} alt="Logo" className="dark-header__logo" />
            </Link>
            <nav className="dark-header__nav">
                {
                    headerNavArr.map((item, index) => (
                        <CustomLink to={`/${item.path}`} key={index} className="dark-header__link link">
                            <>
                                {item.icon && item.icon}
                                {item.name}
                            </>
                        </CustomLink>
                    ))
                }
                <Menu
                    transition
                    menuClassName="dropdown-menu"
                    align="end"
                    menuButton={
                        <div className="dark-header__link link dark-header__link--dropdown">
                            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0.75L13.1627 2.1195C13.3293 2.15652 13.4782 2.24922 13.585 2.3823C13.6918 2.51538 13.75 2.68089 13.75 2.8515V10.3417C13.7499 11.0826 13.567 11.8119 13.2174 12.465C12.8678 13.1181 12.3624 13.6749 11.746 14.0858L7 17.25L2.254 14.0858C1.63771 13.6749 1.13235 13.1183 0.782761 12.4653C0.433177 11.8124 0.250177 11.0832 0.25 10.3425V2.8515C0.250029 2.68089 0.308228 2.51538 0.414992 2.3823C0.521756 2.24922 0.670703 2.15652 0.83725 2.1195L7 0.75ZM7 5.25C6.66981 5.25001 6.34885 5.35898 6.0869 5.55999C5.82495 5.761 5.63664 6.04283 5.55118 6.36177C5.46572 6.6807 5.48788 7.01893 5.61423 7.32399C5.74058 7.62905 5.96406 7.88389 6.25 8.049V11.25H7.75L7.75075 8.049C8.03675 7.88386 8.26025 7.62895 8.38659 7.32381C8.51293 7.01868 8.53503 6.68038 8.44947 6.3614C8.36392 6.04243 8.17548 5.7606 7.9134 5.55965C7.65133 5.35869 7.33025 5.24985 7 5.25Z" fill="white" />
                            </svg>
                            Administration
                        </div>
                    }
                >
                    <CustomMenuItem onClick={handleRedirect} to={workflowPath.path}>{workflowPath.name}</CustomMenuItem>
                    <CustomMenuItem onClick={handleRedirect} to={configTemplate.path}>{configTemplate.name}</CustomMenuItem>
                    <CustomMenuItem onClick={handleRedirect} to={dataprovidersPath.path}>{dataprovidersPath.name}</CustomMenuItem>
                    <CustomMenuItem onClick={handleRedirect} to={rolesPath.path}>{rolesPath.name}</CustomMenuItem>
                    <CustomMenuItem onClick={handleRedirect} to={usersPath.path}>{usersPath.name}</CustomMenuItem>
                </Menu>
            </nav>
            <div className="dark-header__right">
                <Menu
                    transition
                    menuClassName="dropdown-menu"
                    align="end"
                    menuButton={
                        <div className="dark-header__link--dropdown">
                            <div className="dark-header__profile">
                                A
                            </div>
                        </div>
                    }
                >
                    <CustomMenuItem onClick={() => dispatch(userLogout())} to={`/logout}`}>Log out</CustomMenuItem>
                </Menu>
            </div>
        </header >
    )
}

export default DarkHeader