import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'


// Types
import { AppDispatch } from '..'
import { IDashboardFilter } from './dashboard-filter'
import { IReconStoryByIdResponse, IReconStoryItem } from '../../models/response/recon-story-response'
import { IReportDetailsResponse } from '../../models/response/report-response'

// Services
import ReconStoryService from '../../services/recon-story-servise'
import ReportService from '../../services/report-servise'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

interface IDashboard {
    error: any,
    loading: boolean,
    items: IDashboardItem[],
    count: number,
}

export interface IDashboardItem extends IReconStoryItem {
    details: IDashboardItemStoryInstanse
}

export interface IDashboardItemStoryInstanse {
    loading: boolean,
    error: any,
    items: IReconStoryByIdResponse | null,
    instance_result: IReportDetailsResponse | null
}

const initialState: IDashboard = {
    error: null,
    loading: false,
    items: [],
    count: 0,
}

const ReconStory = new ReconStoryService()
const Report = new ReportService()

let cancelToken: CancelTokenSource

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        dashboardFetching(state) {
            state.loading = true
        },
        dashboardFetchingSuccess(state, action: PayloadAction<IDashboardItem[]>) {
            state.loading = false
            state.error = null
            state.items = action.payload
        },
        dashboardFetchingSuccessForCount(state, action: PayloadAction<number>) {
            state.loading = false
            state.error = null
            state.count = action.payload
        },
        dashboardFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        dashboardItemStoryInstanseFetching(state, action: PayloadAction<number>) {
            const itemInx = state.items.findIndex(item => item.id === action.payload)
            if (itemInx >= 0) {
                state.items[itemInx].details.loading = true
            }
        },
        dashboardItemStoryInstanseFetchingSuccess(state, action: PayloadAction<{ id: number, items: IReconStoryByIdResponse, instance_result: IReportDetailsResponse }>) {
            const itemInx = state.items.findIndex(item => item.id === action.payload.id)
            if (itemInx >= 0) {
                state.items[itemInx].details.loading = false
                state.items[itemInx].details.error = null
                state.items[itemInx].details.items = action.payload.items
                state.items[itemInx].details.instance_result = action.payload.instance_result
            }
        },
        dashboardItemStoryInstanseError(state, action: PayloadAction<{ id: number, error: any }>) {
            const itemInx = state.items.findIndex(item => item.id === action.payload.id)
            if (itemInx >= 0) {
                state.items[itemInx].details.loading = false
                state.items[itemInx].details.error = action.payload.error
            }
        },
    }
})

const { dashboardFetching, dashboardFetchingSuccess, dashboardFetchingSuccessForCount, dashboardFetchingError,
    dashboardItemStoryInstanseFetching, dashboardItemStoryInstanseFetchingSuccess,
    dashboardItemStoryInstanseError } = dashboardSlice.actions

export const fetchDashboardItems = (data: IDashboardFilter, currentPage: number, size: number) => async (dispatch: AppDispatch) => {

    try {
        dispatch(dashboardFetching())
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel(cancelTokenErrorMessage)
        }
        cancelToken = axios.CancelToken.source()
        const response = await ReconStory.getReconStoryList(cancelToken, currentPage, size, data);

        dispatch(dashboardFetchingSuccess(
            response.data.items
                .map(item => (
                    {
                        ...item, details: {
                            loading: false,
                            error: null,
                            items: null,
                            instance_result: null
                        }
                    }
                ))
        ))
        dispatch(dashboardFetchingSuccessForCount (
            response.data.count
        ))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(dashboardFetchingError(error))
        }
    }
}

export const fetchDashboardMoreInfo = (id: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(dashboardItemStoryInstanseFetching(id))
        const response = await ReconStory.getReconStoryById(id)
        const instance_id = response.data.reconcilation_story_instances.sort((a: any, b: any) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf())[0]?.id.toString() || ''
        const instance_result = await Report.getReport(instance_id)
        dispatch(dashboardItemStoryInstanseFetchingSuccess({ id, items: response.data, instance_result: instance_result.data }))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(dashboardItemStoryInstanseError({ id, error }))
            throw new Error(error)
        }
    }
}


export const updateDashboardMoreInfo = (id: number) => async (dispatch: AppDispatch) => {
    try {
        const response = await ReconStory.getReconStoryById(id)
        const instance_id = response.data.reconcilation_story_instances.sort((a: any, b: any) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf())[0]?.id.toString() || ''
        const instance_result = await Report.getReport(instance_id)
        dispatch(dashboardItemStoryInstanseFetchingSuccess({ id, items: response.data, instance_result: instance_result.data }))
    } catch (error: any) { }
}

export default dashboardSlice.reducer