import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Components
// import Layout from './components/layout'
import ProtectedRoute from './hoc/protected-route'
import DarkLayout from './components/dark-layout'

// Constants
import { dashboardPath, loginPath, newWorkflowPath, reconciliationPath, resultPath, workflowPath, requestsPath, requestPath, mismatchesPath, configCreate, configTemplate, configTemplateEdit, workflowEdit, dataprovidersPath, dataproviderCreate, dataprovidersEditPath, rolesPath, rolesEditPath, rolesCreatePath, usersPath, usersCreatePath, usersEditPath } from './constants/navigation'
import { useAppSelector } from './hooks/redux'


// Pages
const Login = React.lazy(() => import("./pages/login"))
const Dashboard = React.lazy(() => import("./pages/dashboard"))
const Requests = React.lazy(() => import("./pages/requests"))
const Reconciliation = React.lazy(() => import("./pages/reconciliation"))
const NewWorkflow = React.lazy(() => import("./pages/new-workflow"))
const Result = React.lazy(() => import("./pages/result"))
const Request = React.lazy(() => import("./pages/request"))
const WorkflowTemplates = React.lazy(() => import("./pages/workflow-templates"))
const Mismatches = React.lazy(() => import("./pages/mismatches"))
const ConfigCreate = React.lazy(() => import("./pages/config-create"))
const ConfigTemplate = React.lazy(() => import("./pages/config-template"))
const ConfigTemplateEdit = React.lazy(() => import("./pages/config-template-edit"))
const WorkflowEdit = React.lazy(() => import("./pages/workflow-edit"))
const Dataproviders = React.lazy(() => import('./pages/dataproviders'))
const DataproviderCreate = React.lazy(() => import('./pages/dataprovider-create'))
const DataproviderEdit = React.lazy(() => import('./pages/dataprovider-edit'))
const Roles = React.lazy(() => import('./pages/roles'))
const RolesEdit = React.lazy(() => import('./pages/roles-edit'))
const RolesCreate = React.lazy(() => import('./pages/roles-create'))
const Users = React.lazy(() => import('./pages/users'))
const UsersCreate = React.lazy(() => import('./pages/users-create'))
const UsersEdit = React.lazy(() => import('./pages/users-edit'))

const Router = () => {

    const { authReducer: { isAuth } } = useAppSelector(state => state)

    return (
        <BrowserRouter>
            <Routes>

                <Route index element={<ProtectedRoute
                    redirectСondition={!isAuth}
                    redirectPath={dashboardPath.path}
                >
                    <Suspense fallback={null}><Login /></Suspense>
                </ProtectedRoute>} />

                <Route path={loginPath.path} element={<DarkLayout />}>

                    <Route path={dashboardPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Dashboard /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={requestsPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Requests /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={requestPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Request /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={reconciliationPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Reconciliation /></Suspense>
                    </ProtectedRoute>} />


                    <Route path={workflowPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><WorkflowTemplates /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={newWorkflowPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><NewWorkflow /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={resultPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Result /></Suspense>
                    </ProtectedRoute>} />
                    
                    <Route path={mismatchesPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Mismatches /></Suspense>
                    </ProtectedRoute>} />
                    
                    <Route path={configCreate.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><ConfigCreate /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={configTemplate.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><ConfigTemplate /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={configTemplateEdit.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><ConfigTemplateEdit /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={workflowEdit.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><WorkflowEdit /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={dataprovidersPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><Dataproviders /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={dataproviderCreate.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><DataproviderCreate /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={dataprovidersEditPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={loginPath.path}
                    >
                        <Suspense fallback="Loading..."><DataproviderEdit /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={rolesPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={rolesPath.path}
                    >
                        <Suspense fallback="Loading..."><Roles /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={rolesEditPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={rolesEditPath.path}
                    >
                        <Suspense fallback="Loading..."><RolesEdit /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={rolesCreatePath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={rolesCreatePath.path}
                    >
                        <Suspense fallback="Loading..."><RolesCreate /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={usersPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={usersPath.path}
                    >
                        <Suspense fallback="Loading..."><Users /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={usersCreatePath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={usersCreatePath.path}
                    >
                        <Suspense fallback="Loading..."><UsersCreate /></Suspense>
                    </ProtectedRoute>} />

                    <Route path={usersEditPath.path} element={<ProtectedRoute
                        redirectСondition={isAuth}
                        redirectPath={usersEditPath.path}
                    >
                        <Suspense fallback="Loading..."><UsersEdit /></Suspense>
                    </ProtectedRoute>} />
                </Route>





                {/* 
                    <Route path="signup" element={<ProtectedRoute
                        redirectСondition={!isAuth}
                        redirectPath="/"
                    >
                        <Suspense fallback={null}><Signup /></Suspense>
                    </ProtectedRoute>} /> */}

            </Routes>
        </BrowserRouter>
    )
}

export default Router