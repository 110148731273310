import { AxiosResponse } from "axios";
import $api from "../http";

// Types
import { IReferenceResponse } from "../models/response/reference-response";

export default class ReferenceService {
    getReference(type?: string): Promise<AxiosResponse<IReferenceResponse[]>> {
        return $api.get<IReferenceResponse[]>(`/reference?group_id=${type ? type : 'CALENDAR_PERIOD'}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReferenceSelect(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/reference/ext/group`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getReferenceGroupe(group_id: any): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/reference/ext?group_id=${group_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
}