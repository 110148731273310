import React from 'react';
import { Outlet } from "react-router-dom";
import DarkHeader from '../dark-header';

const DarkLayout: React.FC = () => {
    return (
        <div className="dark-layout">
            <DarkHeader />
            <div className="dark-layout__content">
                <Outlet />
            </div>
        </div>
    );
};

export default DarkLayout;