
interface INavigation {
    name: string,
    path: string,
    isAdmin: boolean
    icon?: React.ReactChild
}

export const loginPath: INavigation = {
    name: 'Login',
    path: '/',
    isAdmin: false
}

export const reconciliationPath: INavigation = {
    name: 'Create new reconciliation',
    path: 'reconciliation',
    isAdmin: false,
    icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM7.25 7.25H4.25V8.75H7.25V11.75H8.75V8.75H11.75V7.25H8.75V4.25H7.25V7.25Z" fill="white" />
    </svg>


}

export const dashboardPath: INavigation = {
    name: 'Dashboard',
    path: 'dashboard',
    isAdmin: false,
    icon: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.25 6.75C0.25 7.30228 0.697715 7.75 1.25 7.75H5.25C5.80228 7.75 6.25 7.30228 6.25 6.75V1.25C6.25 0.697715 5.80228 0.25 5.25 0.25H1.25C0.697715 0.25 0.25 0.697715 0.25 1.25V6.75ZM0.25 12.75C0.25 13.3023 0.697715 13.75 1.25 13.75H5.25C5.80228 13.75 6.25 13.3023 6.25 12.75V10.25C6.25 9.69771 5.80228 9.25 5.25 9.25H1.25C0.697715 9.25 0.25 9.69771 0.25 10.25V12.75ZM7.75 12.75C7.75 13.3023 8.19772 13.75 8.75 13.75H12.75C13.3023 13.75 13.75 13.3023 13.75 12.75V7.25C13.75 6.69772 13.3023 6.25 12.75 6.25H8.75C8.19772 6.25 7.75 6.69772 7.75 7.25V12.75ZM8.75 0.25C8.19772 0.25 7.75 0.697715 7.75 1.25V3.75C7.75 4.30228 8.19772 4.75 8.75 4.75H12.75C13.3023 4.75 13.75 4.30228 13.75 3.75V1.25C13.75 0.697715 13.3023 0.25 12.75 0.25H8.75Z" fill="#FFCF01" />
    </svg>
}

export const mismatchesPath: INavigation = {
    name: 'All mismatches',
    path: 'mismatches',
    isAdmin: false,
    // icon: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M0.25 6.75C0.25 7.30228 0.697715 7.75 1.25 7.75H5.25C5.80228 7.75 6.25 7.30228 6.25 6.75V1.25C6.25 0.697715 5.80228 0.25 5.25 0.25H1.25C0.697715 0.25 0.25 0.697715 0.25 1.25V6.75ZM0.25 12.75C0.25 13.3023 0.697715 13.75 1.25 13.75H5.25C5.80228 13.75 6.25 13.3023 6.25 12.75V10.25C6.25 9.69771 5.80228 9.25 5.25 9.25H1.25C0.697715 9.25 0.25 9.69771 0.25 10.25V12.75ZM7.75 12.75C7.75 13.3023 8.19772 13.75 8.75 13.75H12.75C13.3023 13.75 13.75 13.3023 13.75 12.75V7.25C13.75 6.69772 13.3023 6.25 12.75 6.25H8.75C8.19772 6.25 7.75 6.69772 7.75 7.25V12.75ZM8.75 0.25C8.19772 0.25 7.75 0.697715 7.75 1.25V3.75C7.75 4.30228 8.19772 4.75 8.75 4.75H12.75C13.3023 4.75 13.75 4.30228 13.75 3.75V1.25C13.75 0.697715 13.3023 0.25 12.75 0.25H8.75Z" fill="#FFCF01" />
    // </svg>
}

export const usersPath: INavigation = {
    name: 'Users',
    path: 'users',
    isAdmin: false
}

export const usersEditPath: INavigation = {
    name: 'Users edit',
    path: 'users/:id',
    isAdmin: false
}

export const usersCreatePath: INavigation = {
    name: 'Users create',
    path: 'users-create',
    isAdmin: false
}

export const rolesPath: INavigation = {
    name: 'Roles',
    path: 'roles',
    isAdmin: false
}

export const rolesEditPath: INavigation = {
    name: 'Roles edit',
    path: 'roles/:id',
    isAdmin: false
}

export const rolesCreatePath: INavigation = {
    name: 'Roles create',
    path: 'roles-create',
    isAdmin: false
}

export const reportingPath: INavigation = {
    name: 'Reporting',
    path: 'reporting',
    isAdmin: false
}

export const configCreate: INavigation = {
    name: 'Create Config Templates',
    path: 'config-create',
    isAdmin: false
}

export const configTemplate: INavigation = {
    name: 'Config Templates',
    path: 'config-template',
    isAdmin: false
}

export const configTemplateEdit: INavigation = {
    name: 'Config Template Edit',
    path: 'config-template/:id',
    isAdmin: false
}

export const requestsPath: INavigation = {
    name: 'Requests',
    path: 'requests',
    isAdmin: false,
    icon: <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.250003 8.75H13.75V14.7515C13.75 15.1648 13.4163 15.5 13.0053 15.5H0.994753C0.896691 15.4998 0.799629 15.4803 0.709116 15.4425C0.618603 15.4048 0.536414 15.3496 0.467248 15.2801C0.398082 15.2106 0.343294 15.1281 0.306018 15.0374C0.268742 14.9467 0.249708 14.8496 0.250003 14.7515V8.75ZM0.250003 1.2485C0.250003 0.83525 0.583753 0.5 0.994753 0.5H13.0053C13.4163 0.5 13.75 0.8345 13.75 1.2485V7.25H0.250003V1.2485ZM4.75 2.75V4.25H9.25V2.75H4.75ZM4.75 11V12.5H9.25V11H4.75Z" fill="white" />
    </svg>

}

export const headerNavArr: INavigation[] = [
    dashboardPath,
    reconciliationPath,
    requestsPath,
    mismatchesPath,
]

export const workflowPath: INavigation = {
    name: 'Workflow templates',
    path: 'workflow-templates',
    isAdmin: false
}

export const dataprovidersPath: INavigation = {
    name: 'Dataproviders',
    path: 'dataproviders',
    isAdmin: false
}

export const dataproviderCreate: INavigation = {
    name: 'Dataprovider Create',
    path: 'dataprovider-create',
    isAdmin: false
}

export const dataprovidersEditPath: INavigation = {
    name: 'Dataproviders Edit',
    path: 'dataproviders/:id',
    isAdmin: false
}


export const newWorkflowPath: INavigation = {
    name: 'New workflow',
    path: 'new-workflow',
    isAdmin: false
}
export const workflowEdit: INavigation = {
    name: 'Workflow edit',
    path: 'workflow-templates/:id',
    isAdmin: false
}

export const resultPath: INavigation = {
    name: 'Result',
    path: 'result/:recon_story_id/:instance_id',
    isAdmin: false
}

export const requestPath: INavigation = {
    name: 'Request',
    path: 'request/:id',
    isAdmin: false
}