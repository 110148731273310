import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import DataProviderService from '../../services/data-provider-service'

const Dataprovieder = new DataProviderService()

interface IDataproviderState {
    error: any,
    loading: boolean,
    actionLoading: boolean,
    data: any,
}

const initialState: IDataproviderState = {
    error: null,
    loading: false,
    actionLoading: false,
    data: null,
}

export const dataproviderSlice = createSlice({
    name: 'dataprovider',
    initialState,
    reducers: {
        dataproviderFetching(state) {
            state.loading = true
            state.data = {}
        },
        dataproviderFetchingSuccess(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = null
            state.data = action.payload
        },
        dataproviderFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.actionLoading = false
            state.error = action.payload
        },
        dataproviderItemDeleteFetching(state) {
            state.actionLoading = true
        },
        dataproviderItemDeleteSuccess(state, action: PayloadAction<any>) {
            state.actionLoading = false
            state.data = action.payload
        },
    }
})

export const { dataproviderFetching, dataproviderFetchingSuccess, dataproviderFetchingError, dataproviderItemDeleteFetching, dataproviderItemDeleteSuccess } = dataproviderSlice.actions

export const getDataproviderList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(dataproviderFetching())
        const res = await Dataprovieder.getDataProviderList()
        dispatch(dataproviderFetchingSuccess(res.data))
    } catch (error: any) {
        dispatch(dataproviderFetchingError(error))
        throw new Error(error)
    }
}

export const deleteDataproviderItem = (id: any) => async (dispatch: AppDispatch, getState: any) => {
    const { dataproviderReducer: { data } } = getState()
    const indx = data.findIndex((item: any) => item.id === id)
    const result = [...data]
    result.splice(indx, 1)
    try {
        dispatch(dataproviderItemDeleteFetching())
        await Dataprovieder.deleteDataProvider(id)
        dispatch(dataproviderItemDeleteSuccess(result))
    } catch (error: any) {
        dispatch(dataproviderFetchingError(error))
        throw new Error(error)
    }
}

export default dataproviderSlice.reducer