import * as React from "react"
import {
    useMatch,
    useResolvedPath,
} from "react-router-dom"
import { MenuItem } from "@szhsin/react-menu";

type CustomMenuItemProps = {
    children: React.ReactChild,
    onClick: (to: string) => void,
    to: string
}

const CustomMenuItem: React.FC<CustomMenuItemProps> = ({ children, onClick, to }) => {
    let resolved = useResolvedPath(to);
    let match: any = useMatch({ path: resolved.pathname, end: true });

    return (
        <MenuItem disabled={Boolean(match)} onClick={() => onClick(to)}>{children}</MenuItem>
    )
}

export default CustomMenuItem