import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Constants
import { dashboardTimeFilterList } from "../../constants/dashboard-filter-constants";

export interface IDashboardFilter {
  search: string;
  date?: Date;
  time: string;
  projects: string[];
  users?: string[],
  status?: string[],
}

const initialState: IDashboardFilter = {
  search: "",
  time: dashboardTimeFilterList[0].value,
  projects: ['payments', 'finance', 'trading'],
  users: ['me', 'others'],
  status: ['WAITING', 'IN_PROGRESS', 'RECONCILED', 'UNRECONCILED', 'CREATED', 'FAILED']
};

export const dashboardFilterSlice = createSlice({
  name: "dashboard-filter",
  initialState,
  reducers: {
    dashboardFilterChange(state, action: PayloadAction<IDashboardFilter>) {
      return action.payload;
    },
  },
});

export default dashboardFilterSlice.reducer;

export const { dashboardFilterChange } = dashboardFilterSlice.actions;
