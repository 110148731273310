import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import StatusesService from '../../services/statuses-servise'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

import { IStatusesResponse } from '../../models/response/statuses'

export interface IResultColor {
    loading: boolean,
    error: any,
    statusList: IStatusesResponse[] | null,
}

const initialState: IResultColor = {
    error: null,
    loading: false,
    statusList: null,
}

const Statuses = new StatusesService()

export const statusesListSlice = createSlice({
    name: 'statusesList',
    initialState,
    reducers: {
        statusesListFetching(state) {
            state.loading = true
        },
        statusesListFetchingSuccess(state, action: any) {
            state.loading = false
            state.error = null
            state.statusList = action.payload
        },
        statusesListFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { statusesListFetching, statusesListFetchingSuccess, statusesListFetchingError } = statusesListSlice.actions;

export const getStatusesList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(statusesListFetching())
        const response = await Statuses.getStatusesList()
        dispatch(statusesListFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(statusesListFetchingError(error))
        }
        throw new Error(error)
    }
}

export default statusesListSlice.reducer