import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { ITag, ITagItem } from '../../models/response/report-response'

// Services
import ReconStoryService from '../../services/recon-story-servise'
import ReportService from '../../services/report-servise'


export interface IResultColor {
    loading: boolean,
    error: any,
    tagList: ITagItem[] | null,
}

const initialState: IResultColor = {
    error: null,
    loading: false,
    tagList: null,
}

const ReconStory = new ReconStoryService()
const Report = new ReportService()

export const tagList = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        tagsFetching(state) {
            state.loading = true
        },
        tagsFetchingSuccess(state, action: PayloadAction<ITagItem[] | null>) {
            state.loading = false
            state.error = null
            if (action.payload) {
                state.tagList = action.payload
            }
        },
        tagsFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { tagsFetching, tagsFetchingSuccess, tagsFetchingError } = tagList.actions

export const fetchTagList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(tagsFetching())
        const response = await ReconStory.getTags()
        const result = response.data.map((item) => ({
            ...item, value: { name: JSON.parse(item.value).name, color: JSON.parse(item.value).color }
        }))
        dispatch(tagsFetchingSuccess(result))
    } catch (error: any) {
        dispatch(tagsFetchingError(error))
    }
}

export const addTagAction = (id: string, data: ITag) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tagsFetching())
        await Report.setTag(id, data)
        dispatch(tagsFetchingSuccess(null))
    } catch (error: any) {
        dispatch(tagsFetchingError(error))
        throw new Error(error)
    }
}

export const deleteTagAction = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tagsFetching())
        await Report.deleteTag(id)
        dispatch(tagsFetchingSuccess(null))
    } catch (error: any) {
        dispatch(tagsFetchingError(error))
        throw new Error(error)
    }
}



export default tagList.reducer
