import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import CommentService from '../../services/comment-servise'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'
import { notification } from 'antd'

export type commentType = { field_unique_id: string, recon_story_id?: string | number, content: string, user_id: string, user_name: string }

export interface IResultComments {
    loading: boolean,
    error: any,
    comments: Array<commentType>
}

const initialState: IResultComments = {
    error: null,
    loading: false,
    comments: []
}

const Comment = new CommentService()

export const resultCommentsSlice = createSlice({
    name: 'resultComments',
    initialState,
    reducers: {
        resultCommentsFetching(state) {
            state.loading = true
        },
        resultCommentsFetchingSuccess(state, action: PayloadAction<Array<commentType>>) {
            state.loading = false
            state.error = null
            state.comments = action.payload
        },
        resultCommentsFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        updateComment(state, action: PayloadAction<commentType>) {
            const indx = state.comments.findIndex(item => item.field_unique_id === action.payload.field_unique_id)
            if(indx !== -1){
                state.comments[indx] = action.payload
            } else{
                state.comments.push(action.payload)
            }
        }
    }
})

const { resultCommentsFetching, resultCommentsFetchingSuccess, resultCommentsFetchingError, updateComment } = resultCommentsSlice.actions

export const setResultComments = (comments: Array<commentType>) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultCommentsFetching())
        const response = await Comment.setComments(comments)
        dispatch(updateComment(response[0].data))
        notification['success']({
            message: 'Success',
            description: 'Saved'
        });
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultCommentsFetchingError(error))
        }
        throw new Error(error)
    }
}

export const getResultComments = (instance_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultCommentsFetching())
        const response = await Comment.getCommentList(instance_id || '')
        dispatch(resultCommentsFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultCommentsFetchingError(error))
        }
        throw new Error(error)
    }
}

export const getResultCommentsReconStory = (recon_story_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultCommentsFetching())
        const response = await Comment.getCommentListReconStory(recon_story_id || '')
        dispatch(resultCommentsFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultCommentsFetchingError(error))
        }
        throw new Error(error)
    }
}

export default resultCommentsSlice.reducer
