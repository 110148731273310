import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Constants
import { modalTypes } from '../../constants/modal'

interface IModal {
    modalType: string,
    isOpen: boolean,
    data: any,
}

const initialState = (): IModal[] => {
    let state = [];
    for (const modal in modalTypes) {
        state.push({
            modalType: modalTypes[modal],
            isOpen: false,
            data: null,
        });
    }
    return state;
};

export const modalSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        showModal(state, action: PayloadAction<any>) {
            return state.map(modal => {
                if (modal.modalType === action.payload.modalTypes) {
                    return {
                        ...modal,
                        isOpen: true,
                        data: action.payload.data,
                    };
                } else {
                    return {
                        ...modal,
                        isOpen: false,
                        data: action.payload.data ? action.payload.data : modal.data,
                    };
                }
            });
        },
        hideModal(state, action: PayloadAction<string>) {
            return state.map(modal => {
                if (modal.modalType === action.payload) {
                    return {
                        ...modal,
                        isOpen: false,
                        data: null,
                    };
                } else {
                    return {
                        ...modal,
                        isOpen: false,
                        data: null,
                    };
                }
            });
        },
    }
})

export const { showModal, hideModal } = modalSlice.actions

export default modalSlice.reducer

// export default initialState