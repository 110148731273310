import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'

// Types
import { AppDispatch } from '..'
import { IReportResponse, ISent, ITag } from '../../models/response/report-response'

// Services
import ReportService from '../../services/report-servise'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

// Actions
import { getResultComments, getResultCommentsReconStory } from './result-comments'

export interface IResult {
    loading: boolean,
    error: any,
    items: IReportResponse | null,
}

const initialState: IResult = {
    error: null,
    loading: false,
    items: null,
}

const Report = new ReportService()

let cancelToken: CancelTokenSource

export const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        resultFetching(state) {
            state.loading = true
        },
        resultFetchingSuccess(state, action: PayloadAction<IReportResponse>) {
            state.loading = false
            state.error = null
            state.items = action.payload
        },
        resultFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        addTag(state, action: PayloadAction<ITag>) {
            state.items?.tags.push(action.payload)
        },
        updateSent(state, action: PayloadAction<ISent[]>) {
            if (state.items) {
                state.items.sent = action.payload
            }
        }
    }
})

const { resultFetching, resultFetchingSuccess, resultFetchingError, addTag, updateSent } = resultSlice.actions

export const fetchResultItems = (page: string, matches: string, count: string, instance_id?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultFetching())
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel(cancelTokenErrorMessage)
        }
        cancelToken = axios.CancelToken.source()
        await dispatch(getResultComments(instance_id || ''))
        const response = await Report.getReportList(cancelToken, instance_id || '', page, count, matches)
        dispatch(resultFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultFetchingError(error))
        }
        throw new Error(error)
    }
}

export const fetchResultItemsReconStory = (search: string, page: string, matches: string, count: string, recon_story_id?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultFetching())
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel(cancelTokenErrorMessage)
        }
        cancelToken = axios.CancelToken.source()
        await dispatch(getResultCommentsReconStory(recon_story_id || ''))
        const response = await Report.getReportListReconStory(cancelToken, recon_story_id || '', page, count, matches, search)
        dispatch(resultFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultFetchingError(error))
        }
        throw new Error(error)
    }
}

export const addTagAction = (data: ITag) => async (dispatch: AppDispatch) => {
    dispatch(addTag(data))
}

export const updateSentAction = (data: ISent[]) => async (dispatch: AppDispatch, getState: any) => {
    dispatch(updateSent(data))
}

export default resultSlice.reducer
