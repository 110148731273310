import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { IReferenceResponse } from '../../models/response/reference-response'

// Services
import ReferenceService from '../../services/reference-service'

const Reference = new ReferenceService()

interface IReferenceState {
    error: any,
    loading: boolean,
    reference: IReferenceResponse[]
}

const initialState: IReferenceState = {
    error: null,
    loading: false,
    reference: []
}

export const referenceSlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {
        referenceFetching(state) {
            state.loading = true
            state.reference = []
        },
        referenceFetchingSuccess(state, action: PayloadAction<IReferenceResponse[]>) {
            state.loading = false
            state.error = null
            state.reference = action.payload
        },
        referenceFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { referenceFetching, referenceFetchingSuccess, referenceFetchingError } = referenceSlice.actions

export const getReference = (type?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(referenceFetching())
        const userResponse = await Reference.getReference(type)
        dispatch(referenceFetchingSuccess(userResponse.data))
    } catch (error: any) {
        dispatch(referenceFetchingError(error))
        throw new Error(error)
    }
}

export default referenceSlice.reducer
