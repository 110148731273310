import React, { useEffect } from 'react';

// Hooks
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

// Actions
import { userAuthCheck } from '../../store/reducers/auth';

type AuthCheckerProps = {
    children: React.ReactChild
}

const AuthChecker: React.FC<AuthCheckerProps> = ({ children }) => {
    // const { authReducer: { isAuth } } = useAppSelector(state => state)
    const {authReducer} = useAppSelector(state => state)
    console.log(authReducer)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(userAuthCheck())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (typeof authReducer.isAuth === 'undefined') {
        return null
    }

    return (
        <>
            {children}
        </>
    );
};

export default AuthChecker;