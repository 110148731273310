import axios, { AxiosResponse } from "axios";
import $api from "../http";

// Types
import { commentType } from "../store/reducers/result-comments";

export default class CommentService {
    getCommentList(instance_id: string): Promise<AxiosResponse<commentType[]>> {
        return $api.get<commentType[]>(`/comment?instance_id=${instance_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getCommentListReconStory(recon_story_id: string): Promise<AxiosResponse<commentType[]>> {
        return $api.get<commentType[]>(`/comment/all?recon_story_id=${recon_story_id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    setComment(data: any): Promise<AxiosResponse<AxiosResponse<commentType[]>>> {
        return $api.post<any>(`/comment`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY })
    }

    setComments(data: Array<commentType>): Promise<any> {
        return axios.all(data.map((endpoint) => this.setComment(endpoint))).then(res => {
            return Promise.resolve(res)
        })
    }
}