import { AxiosResponse } from "axios";
import $api from "../http";
import { ICheckboxItem } from "../models/response/report-response";

// Types
import { IWorkflowResponse } from "../models/response/workflow-respnose";
import { IRunWorkflow, IRunWorkflowReconStory } from "../models/response/workflow-types";

export default class WorkflowService {
    getWorkflowList(): Promise<AxiosResponse<IWorkflowResponse[]>> {
        return $api.get<IWorkflowResponse[]>('/workflow', { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    deleteWorkflowTemplate(id: any): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/workflow?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getWorkflowById(id: any): Promise<AxiosResponse<IWorkflowResponse[]>> {
        return $api.get<IWorkflowResponse[]>(`/workflow/${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    runWorkflow(data: IRunWorkflow): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow/run', { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    deployWorkflow(formData: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow/deploy', formData, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY, headers: { "value": "application/x-www-form-urlencoded", 'Content-Type': 'multipart/form-data' } }).then(res => {
            return Promise.resolve(res)
        })
    }
    uploadWorkflow(formData: any, id: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/workflow/fileuploads?workflow_id=${id}`, formData, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY, headers: { "value": "application/x-www-form-urlencoded", 'Content-Type': 'multipart/form-data' } }).then(res => {
            return Promise.resolve(res)
        })
    }
    saveWorkflow(data: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow', {...data}, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    savePutWorkflow(data: any, id: any): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/workflow?id=${id}`, {...data}, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    testWorkflow(data: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow/test', {...data}, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    completeWorkflow(data: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow/complete ', {...data}, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getWorkflowIfno(id: any): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/workflow/info?workflow_id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY, headers: { "value": "application/x-www-form-urlencoded", 'Content-Type': 'multipart/form-data' } }).then(res => {
            return Promise.resolve(res)
        })
    }
    runWorkflowForReconStory(data: IRunWorkflowReconStory): Promise<AxiosResponse<any>> {
        return $api.post<any>('/workflow/restart', { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    saveWorkflowComment(data: any, id: string): Promise<AxiosResponse<AxiosResponse>> {
        return $api.post<any>(`/comment/workflow/${id}`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY })
    }

    addWorkflowTag(data: any, id: string): Promise<AxiosResponse<AxiosResponse>> {
        return $api.post<any>(`/tag/workflow/${id}`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY })
    }

    deleteWorkflowTag(id: string | number): Promise<AxiosResponse<any>> {
        return $api.delete(`/tag/workflow/${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    setWorkflowCheckbox(data: ICheckboxItem[], id: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/supervisor/workflow/${id}`, { items: data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    approveWorkflow(data: Array<string>, id: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/supervisor/workflow/${id}/accept`, { field_unique_ids: data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    deleteWorkflowCheckbox(data: ICheckboxItem[], id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`${process.env.REACT_APP_API_URL_RECON_STORY}/supervisor/workflow/${id}`, { data: { items: data } }).then(res => {
            return Promise.resolve(res)
        })
    }
}