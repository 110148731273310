import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import ConfigService from '../../services/config-service'

const Config = new ConfigService()

interface IConfigTypeState {
    error: any,
    loading: boolean,
    actionLoading: boolean,
    data: any,
}

const initialState: IConfigTypeState = {
    error: null,
    loading: false,
    actionLoading: false,
    data: null,
}

export const confiListSlice = createSlice({
    name: 'confiList',
    initialState,
    reducers: {
        confiListFetching(state) {
            state.loading = true
            state.data = {}
        },
        confiListFetchingSuccess(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = null
            state.data = action.payload
        },
        confiListFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.actionLoading = false
            state.error = action.payload
        },
        confiListItemDeleteFetching(state) {
            state.actionLoading = true
        },
        confiListItemDeleteSuccess(state, action: PayloadAction<any>) {
            state.actionLoading = false
            state.data = action.payload
        },
    }
})

export const { confiListFetching, confiListFetchingSuccess, confiListFetchingError, confiListItemDeleteFetching, confiListItemDeleteSuccess } = confiListSlice.actions

export const getConfigList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(confiListFetching())
        const res = await Config.getConfigTemplate()
        dispatch(confiListFetchingSuccess(res.data))
    } catch (error: any) {
        dispatch(confiListFetchingError(error))
        throw new Error(error)
    }
}

export const deleteConfigItem = (id: any) => async (dispatch: AppDispatch, getState: any) => {
    const { confiListReducer: { data } } = getState()
    const indx = data.findIndex((item: any) => item.id === id)
    const result = [...data]
    result.splice(indx, 1)
    try {
        dispatch(confiListItemDeleteFetching())
        await Config.deleteConfigTemplate(id)
        dispatch(confiListItemDeleteSuccess(result))
    } catch (error: any) {
        dispatch(confiListFetchingError(error))
        throw new Error(error)
    }
}

export default confiListSlice.reducer