import React from 'react';
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

// Styles
import './style/index.sass'

// Router
import Router from './router'

// Store
import { setupStore } from "./store"

// Components
import AuthChecker from './hoc/auth-checker';

const store = setupStore();
const persistor = persistStore(store)

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthChecker>
          <Router />
        </AuthChecker>
      </PersistGate>
    </Provider>
  )
}

export default App