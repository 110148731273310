import * as React from "react"
import {
    Link,
    useMatch,
    useResolvedPath,
} from "react-router-dom"
import type { LinkProps } from "react-router-dom"

const CustomLink: React.FC<LinkProps> = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link
            to={to}
            {...props}
            className={props.className ? `${props.className}${match ? ' active' : ''}` : `${match ? 'active' : ''}`}
        >
            {children}
        </Link>
    )
}

export default CustomLink