import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import UserService from '../../services/user-service'

const User = new UserService()

export interface IUser {
    id: string,
    department_name: string,
    role_name: string
}

interface IUserState {
    error: any,
    loading: boolean,
    user: IUser | null
}

const initialState: IUserState = {
    error: null,
    loading: false,
    user: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userFetching(state) {
            state.loading = true
        },
        userFetchingSuccess(state, action: PayloadAction<IUser>) {
            state.loading = false
            state.error = null
            state.user = action.payload
        },
        userFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { userFetching, userFetchingSuccess, userFetchingError } = userSlice.actions

export const getUser = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(userFetching())
        const userResponse = await User.getUserById()
        dispatch(userFetchingSuccess(userResponse.data))
    } catch (error: any) {
        dispatch(userFetchingError(error))
        throw new Error(error)
    }
}

export default userSlice.reducer

// const { authFetching } = authSlice.actions
// export default initialState