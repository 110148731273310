import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { IRequestItemResponse } from '../../models/response/request-response'

// Services
import RequestService from '../../services/request-service'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

export interface IResultColor {
    loading: boolean,
    error: any,
    list: IRequestItemResponse[] | null,
}

const initialState: IResultColor = {
    error: null,
    loading: false,
    list: null,
}

const Request = new RequestService()

export const requestListSlice = createSlice({
    name: 'requestList',
    initialState,
    reducers: {
        requestListFetching(state) {
            state.loading = true
        },
        requestListFetchingSuccess(state, action: any) {
            state.loading = false
            state.error = null
            state.list = action.payload
        },
        requestListFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { requestListFetching, requestListFetchingSuccess, requestListFetchingError } = requestListSlice.actions;

export const getRequestList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(requestListFetching())
        const response = await Request.getRequestList()
        dispatch(requestListFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(requestListFetchingError(error))
        }
        throw new Error(error)
    }
}

export default requestListSlice.reducer