import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { IConfigResponse } from '../../models/response/config-response'

// Services
import ConfigService from '../../services/config-service'

const Config = new ConfigService()

interface IConfigTypeState {
    error: any,
    loading: boolean,
    data: IConfigResponse | null,
    result: any
}

const initialState: IConfigTypeState = {
    error: null,
    loading: false,
    data: null,
    result: {}
}

export const confiTypeSlice = createSlice({
    name: 'confiType',
    initialState,
    reducers: {
        confiTypeFetching(state) {
            state.loading = true
            state.result = {}
        },
        confiTypeFetchingSuccess(state, action: PayloadAction<IConfigResponse>) {
            state.loading = false
            state.error = null
            state.data = action.payload
        },
        confiTypeFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = action.payload
        },
        setConfigResult(state, action: PayloadAction<any>) {
            state.result = action.payload
        },
        confiTypeReset(state) {
            state.loading = false
            state.error = null
            state.data = null
            state.result = {}
        },
    }
})

export const { confiTypeFetching, confiTypeFetchingSuccess, confiTypeFetchingError, setConfigResult, confiTypeReset } = confiTypeSlice.actions

export const getConfigTypes = (code: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(confiTypeFetching())
        const res = await Config.getConfig(code)
        dispatch(confiTypeFetchingSuccess(res.data))
    } catch (error: any) {
        dispatch(confiTypeFetchingError(error))
        throw new Error(error)
    }
}

export const setConfigTypesResult = (value: any) => (dispatch: AppDispatch, getState: any) => {
    const { confiTypeReducer: { result: stateResult } } = getState()
    const result: any = { ...stateResult }
    Object.keys(value).forEach(item => {
        if (value[item].multy) {
            delete value[item].multy
            Object.keys(value[item]).forEach(obj => {
                result[item] = {
                    ...result[item],
                    ...value[item],
                    isMulty: true

                }
            })
        } else {
            result[item] = value[item]
        }
    })
    const data: any = {}
    Object.keys(result).forEach((item) => {
        if (result[item].isMulty) {
            delete result[item].isMulty
            data[item] = Object.keys(result[item]).map(key => (result[item][key]))
        } else {
            data[item] = result[item]
        }
    })

    dispatch(setConfigResult(data))
}

export default confiTypeSlice.reducer
