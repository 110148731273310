import { AxiosResponse } from "axios";
import $api from "../http";

// Types
import { IConfigResponse } from "../models/response/config-response";

export default class ConfigService {
    getConfig(code: number): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/config_type/base/${code}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    setConfigTemplate(data: any): Promise<AxiosResponse<IConfigResponse>> {
        return $api.post<IConfigResponse>(`/config_template`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    putConfigTemplate(id: any, data: any): Promise<AxiosResponse<IConfigResponse>> {
        return $api.put<IConfigResponse>(`/config_template?id=${id}`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getConfigTemplate(): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/config_template`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    getConfigTemplateByCode(code: string): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/config_template?code=${code}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    getConfigTemplateById(id: string): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/config_template?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    getDataProvider(): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/data_provider`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }

    getDepartments (): Promise<AxiosResponse<IConfigResponse>> {
        return $api.get<IConfigResponse>(`/departments `).then(res => {
            return Promise.resolve(res)
        })
    }

    deleteConfigTemplate(id: any): Promise<AxiosResponse<IConfigResponse>> {
        return $api.delete<IConfigResponse>(`/config_template?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
}