import { AxiosResponse } from "axios";
import $api from "../http";

interface AuthResponse {
    access_token: "string";
    refresh_token: "string"
}

export default class AuthService {
    async login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/auth/login', { login, password })
    }
}