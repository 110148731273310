import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import WorkflowService from '../../services/workflow-service'

const Workflow = new WorkflowService()

interface IWorklowTypeState {
    error: any,
    loading: boolean,
    actionLoading: boolean,
    data: any,
}

const initialState: IWorklowTypeState = {
    error: null,
    loading: false,
    actionLoading: false,
    data: null,
}

export const workflowListSlice = createSlice({
    name: 'workflowList',
    initialState,
    reducers: {
        workflowListFetching(state) {
            state.loading = true
            state.data = {}
        },
        workflowListFetchingSuccess(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = null
            state.data = action.payload
        },
        workflowListFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.actionLoading = false
            state.error = action.payload
        },
        workflowListItemDeleteFetching(state) {
            state.actionLoading = true
        },
        workflowListItemDeleteSuccess(state, action: PayloadAction<any>) {
            state.actionLoading = false
            state.data = action.payload
        },
    }
})

export const { workflowListFetching, workflowListFetchingSuccess, workflowListFetchingError, workflowListItemDeleteFetching, workflowListItemDeleteSuccess } = workflowListSlice.actions

export const getWorkflowList = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(workflowListFetching())
        const res = await Workflow.getWorkflowList()
        dispatch(workflowListFetchingSuccess(res.data))
    } catch (error: any) {
        dispatch(workflowListFetchingError(error))
        throw new Error(error)
    }
}

export const deleteWorkflowItem = (id: any) => async (dispatch: AppDispatch, getState: any) => {
    const { workflowListReducer: { data } } = getState()
    const indx = data.findIndex((item: any) => item.id === id)
    const result = [...data]
    result.splice(indx, 1)
    try {
        dispatch(workflowListItemDeleteFetching())
        await Workflow.deleteWorkflowTemplate(id)
        dispatch(workflowListItemDeleteSuccess(result))
    } catch (error: any) {
        dispatch(workflowListFetchingError(error))
        throw new Error(error)
    }
}

export default workflowListSlice.reducer