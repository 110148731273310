import { AxiosResponse } from "axios";
import $api from "../http";


export default class RequestService {
  getRequestList(): Promise<AxiosResponse> {
    return $api
      .get(`/supervisor`, {
        baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
      })
      .then((res) => {
        return Promise.resolve(res);
      });
  }

  getRequestItem(id:string): Promise<AxiosResponse> {
    return $api
      .get(`/supervisor/${id}`, {
        baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
      })
      .then((res) => {
        return Promise.resolve(res);
      });
  }

  acceptSupervisorTasks(recon_story_id: number, field_unique_ids: {}[]): Promise<AxiosResponse<any>> {
    return $api.post<any>('/supervisor/accept', { recon_story_id, field_unique_ids }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
        return Promise.resolve(res)
    })
}
}
