import { AxiosResponse, CancelTokenSource } from "axios";
import $api from "../http";

export default class StatusesService {
    getStatusesList(): Promise<AxiosResponse> {
      return $api
        .get(`/instance/status`, {
          baseURL: process.env.REACT_APP_API_URL_RECON_STORY,
        })
        .then((res) => {
          return Promise.resolve(res);
        });
    }

  }