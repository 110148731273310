import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { IReportColorResponse } from '../../models/response/report-response'
import { IReconStoryByIdResponse } from '../../models/response/recon-story-response'

// Services
import ReportService from '../../services/report-servise'
import ReconStoryService from '../../services/recon-story-servise'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

export interface IResultColor {
    loading: boolean,
    error: any,
    // items: IReconStoryByIdResponse | null
    items: IReportColorResponse | null,
    reconStory: IReconStoryByIdResponse | null,
}

const initialState: IResultColor = {
    error: null,
    loading: false,
    items: null,
    reconStory: null,
}

const Report = new ReportService()
const ReconStory = new ReconStoryService()

export const resultSliceColor = createSlice({
    name: 'resultColor',
    initialState,
    reducers: {
        resultColorFetching(state) {
            state.loading = true
        },
        resultReconStoryFetchingSuccess(state, action: PayloadAction<IReconStoryByIdResponse>) {
            state.reconStory = action.payload
        },
        resultColorFetchingSuccess(state, action: PayloadAction<IReportColorResponse>) {
            state.loading = false
            state.error = null
            state.items = action.payload
        },
        resultColorFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { resultColorFetching, resultColorFetchingSuccess, resultColorFetchingError, resultReconStoryFetchingSuccess } = resultSliceColor.actions

export const fetchResultColors = (instance_id?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultColorFetching())
        const response = await Report.getReportColor(instance_id || '')
        dispatch(resultColorFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultColorFetchingError(error))
        }
    }
}

export const fetchResultColorsReconStory = (recon_story_id?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(resultColorFetching())
        const response = await Report.getReportColorReconStory(recon_story_id || '')
        const reconStoryResponse = await ReconStory.getReconStoryById(recon_story_id || '')
        dispatch(resultReconStoryFetchingSuccess(reconStoryResponse.data))
        dispatch(resultColorFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(resultColorFetchingError(error))
        }
    }
}

export default resultSliceColor.reducer
