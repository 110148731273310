import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// Utils
import getTokens from '../utils/get-tokens';
import setTokens from '../utils/set-tokens';

const $api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
})

$api.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
        config.headers = {};
    }
    const { access } = getTokens()
    if (access) {
        config.headers.Authorization = `Bearer ${access}`
    }
    return config
})


$api.interceptors.response.use((config: AxiosResponse) => {
    return config
}, async (error) => {
    const originalReques = error.config
    const { refresh } = getTokens()
    if (error.response.status === 401 && refresh && error.config && !error.config._isReady) {
        originalReques._isReady = true
        try {
            const response = await $api.post('/auth/refresh', { refresh_token: refresh })
            setTokens(response.data.access_token, response.data.refresh_token)
            return $api.request(originalReques)
        } catch (e) {
            document.location.href="/";
            throw error
        }
    }
    throw error
})
export default $api