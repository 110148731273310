import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'
import { IWorkflowResponse } from '../../models/response/workflow-respnose'

// Services
import WorkflowService from '../../services/workflow-service'

const Workflow = new WorkflowService()

interface IWorkflowState {
    error: any,
    loading: boolean,
    workflow: IWorkflowResponse[]
}

const initialState: IWorkflowState = {
    error: null,
    loading: false,
    workflow: []
}

export const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        workflowFetching(state) {
            state.loading = true
        },
        workflowFetchingSuccess(state, action: PayloadAction<IWorkflowResponse[]>) {
            state.loading = false
            state.error = null
            state.workflow = action.payload
        },
        workflowFetchingError(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { workflowFetching, workflowFetchingSuccess, workflowFetchingError } = workflowSlice.actions

export const getWorkflow = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(workflowFetching())
        const userResponse = await Workflow.getWorkflowList()
        dispatch(workflowFetchingSuccess(userResponse.data))
    } catch (error: any) {
        dispatch(workflowFetchingError(error))
        throw new Error(error)
    }
}

export default workflowSlice.reducer
