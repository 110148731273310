import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { notification } from 'antd'

// Types
import { AppDispatch } from '..'
import { IMismatchResponse } from '../../models/response/miesmatch-response'
import { commentType } from './result-comments'
import { ISent, ITag } from '../../models/response/report-response'

// Services
import MismatchesService from '../../services/mismatches-service'
import WorkflowService from '../../services/workflow-service'

const Workflow = new WorkflowService()


export interface IResult {
    loading: boolean,
    error: any,
    data: IMismatchResponse | null,
}

const initialState: IResult = {
    error: null,
    loading: false,
    data: null,
}

const Mismatches = new MismatchesService()

export const mismathesSlice = createSlice({
    name: 'mismathes',
    initialState,
    reducers: {
        mismathesFetching(state) {
            state.loading = true
        },
        mismathesFetchingSuccess(state, action: PayloadAction<IMismatchResponse>) {
            state.loading = false
            state.error = null
            state.data = action.payload
        },
        mismathesFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
            state.data = null
        },
        mismathesUpdateComment(state, action: PayloadAction<commentType>) {
            state.loading = false
            if (state.data) {
                const indx = state.data.comments.findIndex(item => item.field_unique_id === action.payload.field_unique_id)
                if (indx !== -1) {
                    state.data.comments[indx] = action.payload
                } else {
                    state.data.comments.push(action.payload)
                }
            }
        },
        mismatchesAddSent(state, action: PayloadAction<ISent>) {
            if (state.data) {
                state.data.sent.push(action.payload)
            }
        },
        mismatchesDeleteSent(state, action: PayloadAction<string>) {
            if (state.data) {
                state.data.sent = state.data.sent.filter(item => item.field_unique_id !== action.payload)
            }
        },
        mismatchesDeleteAllSent(state) {
            if (state.data) {
                state.data.sent = []
            }
        },
        mismatchesSetSeveralSent(state, action: PayloadAction<ISent[]>) {
            if (state.data) {
                state.data.sent = [...state.data.sent, ...action.payload]
            }
        }
    }
})

export const { mismathesFetching, mismathesFetchingSuccess, mismathesFetchingError, mismathesUpdateComment, mismatchesAddSent, mismatchesDeleteSent, mismatchesDeleteAllSent, mismatchesSetSeveralSent } = mismathesSlice.actions

export const fetchMismatchesItems = (id: string, page: string, count: string, search?: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(mismathesFetching())
        const response = await Mismatches.getMismatches(id, page, count, search)
        dispatch(mismathesFetchingSuccess(response.data))
    } catch (error: any) {
        dispatch(mismathesFetchingError(error))
        throw new Error(error)
    }
}

export const setMismatchesCommentsAction = (comments: commentType, id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(mismathesFetching())
        const response: any = await Workflow.saveWorkflowComment(comments, id)
        console.log(response)
        dispatch(mismathesUpdateComment(response.data))
        notification['success']({
            message: 'Success',
            description: 'Saved'
        });
    } catch (error: any) {
        dispatch(mismathesFetchingError(error))
        throw new Error(error)
    }
}

export default mismathesSlice.reducer
