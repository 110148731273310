import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Reducers
import authReducer from './reducers/auth'
import userReducer from './reducers/user'
import dashboardFilterReducer from './reducers/dashboard-filter'
import dashboardReducer from './reducers/dashboard'
import modalReducer from './reducers/modal'
import workflowReducer from './reducers/workflow'
import referenceReducer from './reducers/reference'
import resultReducer from './reducers/result'
import resultColorReducer from './reducers/result-color'
import resultCommentsReducer from './reducers/result-comments'
import tagListReducer from './reducers/tags'
import requestListReducer from "./reducers/request";
import requestItemReducer from "./reducers/request-item";
import statusesListReducer from "./reducers/statuses";
import mismatchesReducer from "./reducers/mismatches";
import confiTypeReducer from "./reducers/config-type";
import confiListReducer from "./reducers/config-list";
import workflowListReducer from "./reducers/workflow-list";
import dataproviderReducer from "./reducers/dataproviders";
import dataproviderItemReducer from "./reducers/dataprovider-item";


const persistConfig = {
    key: 'rt-root',
    version: 1,
    storage: storage,
}

const rootReducer = combineReducers({
    userReducer,
    authReducer,
    dashboardReducer,
    resultReducer,
    resultColorReducer,
    resultCommentsReducer,
    modalReducer,
    workflowReducer,
    referenceReducer,
    tagListReducer,
    requestListReducer,
    requestItemReducer,
    statusesListReducer,
    mismatchesReducer,
    confiTypeReducer,
    confiListReducer,
    workflowListReducer,
    dataproviderReducer,
    dataproviderItemReducer,
    dashboardFilterReducer: persistReducer(persistConfig, dashboardFilterReducer),
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']