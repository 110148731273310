import { AxiosResponse } from "axios";
import $api from "../http";
import { IMismatchResponse } from "../models/response/miesmatch-response";

export default class MismatchesService {
    getMismatches(id: string, page: string, count: string, search?: string): Promise<AxiosResponse<IMismatchResponse>> {
        return $api.get<IMismatchResponse>(`/mismatches?workflow_id=${id}&page=${page}&size=${count}${Boolean(search) ? `&search=${search}` : ''}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then((res) => {
            return Promise.resolve(res);
        });
    }
}