import { AxiosResponse } from "axios";
import $api from "../http";

export default class DataProviderService {
    getDataProviderList(): Promise<AxiosResponse<any>> {
        return $api.get<any>('/data_provider', { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    deleteDataProvider(id: any): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/data_provider?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getDataProviderType(code: number): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/data_provider/base?code=${code}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    setDataprovider(data: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/data_provider`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    getDataproviderById(id: string): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/data_provider?id=${id}`, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
    putDataprovider(id: any, data: any): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/data_provider?id=${id}`, { ...data }, { baseURL: process.env.REACT_APP_API_URL_RECON_STORY }).then(res => {
            return Promise.resolve(res)
        })
    }
}