import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Types
import { AppDispatch } from '..'

// Services
import RequestService from '../../services/request-service'

// Constants
import { cancelTokenErrorMessage } from '../../constants/api'

export interface IResultColor {
    loading: boolean,
    error: any,
    // item: IRequestItemResponse[] | null,
    item: any,
}

const initialState: IResultColor = {
    error: null,
    loading: true,
    item: null,
}

const Request = new RequestService()

export const requestItemSlice = createSlice({
    name: 'requestItem',
    initialState,
    reducers: {
        requestItemFetching(state) {
            state.loading = true
        },
        requestItemFetchingSuccess(state, action: any) {
            state.loading = false
            state.error = null
            state.item = action.payload
        },
        requestItemFetchingError(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    }
})

const { requestItemFetching, requestItemFetchingSuccess, requestItemFetchingError } = requestItemSlice.actions;

export const getRequestItem = (id:string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(requestItemFetching())
        const response = await Request.getRequestItem(id)
        dispatch(requestItemFetchingSuccess(response.data))
    } catch (error: any) {
        if (error?.message !== cancelTokenErrorMessage) {
            dispatch(requestItemFetchingError(error))
        }
        throw new Error(error)
    }
}

export default requestItemSlice.reducer